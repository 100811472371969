import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import SHeaderSection from '@/components/stemdo-components/s-header-section/SHeaderSection.vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import TeamRequestOverview from './team-request-overview/TeamRequestOverview.vue';
import ButtonsGroup from './buttons-group/ButtonsGroup.vue';
import HeaderTeamRequestStatus from './header-team-request-status/HeaderTeamRequestStatus.vue';
import StemdoerList from './stemdoer-list/StemdoerList.vue';
import { useRouter } from '@/plugins/router';
import { TeamRequest } from '@/shared/model/team-request.model';
import { IStemdoerRate, StemdoerRate, StemdoerRates } from '@/shared/model/stemdoer-rate.model';
import store from '@/plugins/vuex';
import SPopUp from '@/components/stemdo-components/s-pop-up/SPopUp.vue';
import { ExtendedTeamRequestStatus } from './enum/extendedTeamRequestStatus';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { IRate } from '@/shared/model/rate.model';
import SPdfTeamRequest from '@/components/stemdo-components/s-pdf-teamrequest/SPdfTeamRequest.vue';
import SExternalLink from '@/components/stemdo-components/s-external-link/SExternalLink.vue';

export default defineComponent({
  props: {
    teamRequestId: {
      type: String,
      required: true
    }
  },
  components: {
    SHeaderSection,
    TeamRequestOverview,
    ButtonsGroup,
    HeaderTeamRequestStatus,
    StemdoerList,
    SPdfTeamRequest,
    SPopUp,
    SExternalLink
  },
  setup(props) {
    const router = useRouter();
    const isUserManager = computed(() => store.getters.account?.authorities.includes('ROLE_GESTOR') ?? false);
    const isUserClient = computed(() => store.getters.account?.authorities.includes('ROLE_CLIENT') ?? false);
    const isAvailableStemdoersModalOpen = ref(false);
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const teamRequest = ref<TeamRequest>(TeamRequest.empty());
    const originalStemdoerRates = ref<IStemdoerRate[]>([]);
    const errorPopUp = ref(false);
    const successPopUp = ref(false);
    const successMessage = ref('');
    const isAnyRateChanged = computed(() => {
      return teamRequest.value.stemdoerRates?.some(sr => {
        const originalRate = originalStemdoerRates.value.find(osr => osr.stemdoer.id === sr.stemdoer.id)?.rate;
        return sr.rate !== originalRate;
      });
    });
    const areStemdoersChanged = ref(false);
    const extendedTeamRequestStatus = computed(() => {
      const { status, modifiedByManager, areAllStemdoereAvailable } = teamRequest.value;
      const userModified = isAnyRateChanged.value || areStemdoersChanged.value;
      if (isUserManager.value) {
        if (status === RequestStatus.PENDING) {
          return userModified
            ? ExtendedTeamRequestStatus.MANAGER_AND_PENDING_AND_USER_MODIFIED
            : ExtendedTeamRequestStatus.MANAGER_AND_PENDING_AND_USER_NOT_MODIFIED;
        }
        if (status === RequestStatus.VALIDATED) {
          return userModified
            ? ExtendedTeamRequestStatus.MANAGER_AND_VALIDATED_AND_USER_MODIFIED
            : ExtendedTeamRequestStatus.MANAGER_AND_VALIDATED_AND_USER_NOT_MODIFIED;
        }
      }
      if (isUserClient.value) {
        if (status === RequestStatus.CANCELLED_BY_MANAGER) {
          return ExtendedTeamRequestStatus.CLIENT_AND_CANCELLED;
        }
        if (status === RequestStatus.SAVED) {
          if (!teamRequest.value.initDate) {
            return ExtendedTeamRequestStatus.CLIENT_AND_SAVED;
          }
          return areAllStemdoereAvailable()
            ? ExtendedTeamRequestStatus.CLIENT_AND_SAVED
            : ExtendedTeamRequestStatus.CLIENT_AND_SAVED_AND_NOT_ALL_STEMDOERS_AVAILABLE;
        }
        if (status === RequestStatus.PENDING) {
          return ExtendedTeamRequestStatus.CLIENT_AND_PENDING;
        }
        if (status === RequestStatus.VALIDATED) {
          return areAllStemdoereAvailable()
            ? modifiedByManager
              ? ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_MODIFIED_BY_MANAGER
              : ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_NOT_MODIFIED_BY_MANAGER
            : ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_NOT_ALL_STEMDOERS_AVAILABLE;
        }
        if (status === RequestStatus.ON_GOING) {
          return ExtendedTeamRequestStatus.CLIENT_AND_ON_GOING;
        }
      }
      return undefined;
    });

    onMounted(() => {
      retrieveTeamRequest(props.teamRequestId);
    });

    const retrieveTeamRequest = (id: string, isRefresh?: boolean) => {
      teamRequestService
        .find(id)
        .then(tr => {
          teamRequest.value = new TeamRequest(tr);
          originalStemdoerRates.value = tr.stemdoerRates?.map(sr => Object.assign({}, sr));
          if (
            teamRequest.value.status !== RequestStatus.CANCELLED_BY_CLIENT &&
            teamRequest.value.status !== RequestStatus.CANCELLED_BY_MANAGER &&
            teamRequest.value.status !== RequestStatus.ON_GOING
          ) {
            if (!teamRequest.value.areAllStemdoereAvailable() && isUserManager.value === true) {
              openAvailableStemdoersModal();
            }
          }
        })
        .catch(error => {
          console.error(error);
        });
    };

    const updateStemdoerRate = (stemdoerId: string, newRate: number) => {
      teamRequest.value.stemdoerRates.find(sr => sr.stemdoer.id === stemdoerId).rate = newRate;
    };

    const refreshTeamRequest = (id?) => {
      if (id) {
        retrieveTeamRequest(id, true);
        return;
      }
      areStemdoersChanged.value = false;
      retrieveTeamRequest(teamRequest.value.id, true);
    };

    const handleSuccess = (message: string) => {
      successMessage.value = message;
      successPopUp.value = true;
    };

    const handleError = () => {
      errorPopUp.value = true;
    };

    const handleClosePopUp = () => {
      errorPopUp.value = false;
      successPopUp.value = false;
    };

    const closeAvailableStemdoersModal = () => {
      isAvailableStemdoersModalOpen.value = false;
    };

    const openAvailableStemdoersModal = () => {
      isAvailableStemdoersModalOpen.value = true;
    };

    const handleContinueAction = () => {
      teamRequest.value.removeNotAvailablesStemdoer();
      areStemdoersChanged.value = true;
      closeAvailableStemdoersModal();
    };

    const gotoExplore = () => {
      router.push({
        name: 'exploreManager',
        query: { tr: teamRequest.value.id }
      });
    };

    return {
      isUserClient,
      teamRequest,
      extendedTeamRequestStatus,
      isAnyRateChanged,
      areStemdoersChanged,
      isAvailableStemdoersModalOpen,
      errorPopUp,
      successPopUp,
      successMessage,
      updateStemdoerRate,
      refreshTeamRequest,
      handleSuccess,
      handleError,
      closeAvailableStemdoersModal,
      handleContinueAction,
      gotoExplore,
      handleClosePopUp
    };
  }
});
