import { render, staticRenderFns } from "./CandidatesDetail.vue?vue&type=template&id=9e58f7e4&scoped=true"
import script from "./candidatesDetail.ts?vue&type=script&lang=ts&external"
export * from "./candidatesDetail.ts?vue&type=script&lang=ts&external"
import style0 from "./CandidatesDetail.vue?vue&type=style&index=0&id=9e58f7e4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e58f7e4",
  null
  
)

export default component.exports