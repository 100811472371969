import { render, staticRenderFns } from "./SExternalLink.vue?vue&type=template&id=15846c35&scoped=true"
import script from "./sExternalLink.ts?vue&type=script&lang=ts&external"
export * from "./sExternalLink.ts?vue&type=script&lang=ts&external"
import style0 from "./SExternalLink.vue?vue&type=style&index=0&id=15846c35&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15846c35",
  null
  
)

export default component.exports