import { render, staticRenderFns } from "./SSearch.vue?vue&type=template&id=55e87389&scoped=true"
import script from "./sSearch.ts?vue&type=script&lang=ts&external"
export * from "./sSearch.ts?vue&type=script&lang=ts&external"
import style0 from "./SSearch.vue?vue&type=style&index=0&id=55e87389&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55e87389",
  null
  
)

export default component.exports