import { render, staticRenderFns } from "./SPdfTeamRequest.vue?vue&type=template&id=22b89948&scoped=true"
import script from "./sPdfTeamRequest.ts?vue&type=script&lang=ts&external"
export * from "./sPdfTeamRequest.ts?vue&type=script&lang=ts&external"
import style0 from "./SPdfTeamRequest.vue?vue&type=style&index=0&id=22b89948&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22b89948",
  null
  
)

export default component.exports